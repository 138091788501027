import React from 'react';
import './privacy.style.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Privacy({ onClose }) {
    return (
        <div className="privacy-modal">
            <div className="privacy-content">
                <p>Политика конфиденциальности</p>
                <FontAwesomeIcon icon={faTimes} color='red' className='closeIcon' onClick={onClose}  />
                <div className='info_cont'>
                    <div className='info'>
                        <p className='info_text'>г.Калуга</p>
                    </div>
                    <div className='info'>
                        <p className='info_text'>«10» мая 2023 г.</p>
                    </div>
                </div>
                <p className='info_desc'>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) является неотъемлемой частью Сайта, размещенной на Сайте в сети Интернет по адресу: <span className='link'>https://sbsp.ru/</span>  (далее – Сайт).</p>
                <p className='info_desc'>Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов.</p>

                <p className='info_title'>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                <p className="info_desc">1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:</p>
                <p className="info_desc">1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) или в процессе использования Сервисов, включая персональные данные Пользователя. Обязательная для предоставления Сервисов информация помечена специальным образом.</p>
                <p className="info_desc">1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.</p>
                <p className="info_desc">1.1.3. Настоящая Политика конфиденциальности применяется только к Сайту <span className="link">https://sbsp.ru/</span>. Сайт <span className='link'>https://sbsp.ru/</span>  не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте <span className="link">https://sbsp.ru/</span>.</p>
                <p className="info_title">2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</p>
                <p className="info_desc">2.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</p>
                <p className="info_desc">В случае получения уведомления от Пользователя об отзыве согласия на обработку персональных данных Сайт прекращает обработку персональных данных Пользователя в срок, не превышающий 1 месяц с момента получения.</p>
                <p className="info_desc">Уведомление об отзыве согласия на обработку персональных данных направляется на адрес электронной почты: <a href="mailto:interleads.kaluga@yandex.ru" className="link">interleads.kaluga@yandex.ru</a> , а также путем письменного обращения по юридическому адресу: 248008, РФ, город Калуга, улица Тарутинская, дом 223, офис 3.</p>
                <p className="info_desc">Уведомление об отзыве согласия будет считаться полученным с момента получения отзыва на бумажном носителе путем получения оригинала отзыва на бумажном носителе посредством почтового отправления.</p>
                <p className="info_desc">Уведомление об отзыве на обработку персональных данных считается надлежащим образом, оформленным при наличии следующих реквизитов, позволяющих идентифицировать субъекта персональных данных: полное ФИО, адрес регистрации, паспортные данные, номер мобильного телефона, адрес электронной почты.</p>
                <p className="info_desc">В случае отсутствия обязательных реквизитов, уведомление об отзыве считается не направленным субъектом обработки персональных данных и не полученным Сайтом (оператором обработки персональных данных).</p>
                <p className="info_desc">2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</p>
                <p className="info_desc">Идентификации Пользователя, зарегистрированного на Сайте.</p>
                <p className="info_desc">Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</p>
                <p className="info_desc">Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.</p>
                <p className="info_desc">Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
                <p className="info_desc">Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем</p>
                <p className="info_desc">Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</p>
                <p className="info_desc">Иных целей незапрещенных законодательством Российской Федерации.</p>
                <p className='info_title'>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</p>
                <p className="info_desc">3.1. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.</p>
                <p className="info_desc">3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональной информации становится общедоступной.</p>
                <p className="info_desc">3.3. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
                <p className="info_desc">3.3.1. Пользователь выразил согласие на такие действия.</p>
                <p className="info_desc">3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем.</p>
                <p className="info_desc">3.3.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.</p>
                <p className="info_desc">3.3.5. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
                <p className="info_desc">3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока следующими способами: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным <a href="consultantplus://offline/ref=0CC64BEAF132A702991ADE949CA514FF4E9BA141C65004982DD5C35C8B7CA3E30A4A692C75FABCE4DBEC6C7F5ET7J4S" className="link">законом</a> от 27.07.2006 N 152-ФЗ “О персональных данных”.</p>
                <p className="info_desc">3.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
                <p className="info_desc">3.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.    </p>
                <p className="info_desc">3.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
                <p className="info_title">4. ОБЯЗАТЕЛЬСТВА СТОРОН</p>
                <p className="info_desc">4.1. Пользователь обязан:</p>
                <p className="info_desc">4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</p>
                <p className="info_desc">4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной информации.</p>
                <p className="info_desc">4.2. Администрация Сайта обязана:</p>
                <p className="info_desc">4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности.</p>
                <p className="info_desc">4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности.</p>
                <p className="info_desc">4.2.3. Для обеспечения защиты персональных данных Пользователя при их обработке приняты следующие правовые, организационные и технические меры от несанкционированного, неправомерного или случайного доступа к персональным данным, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.</p>
                <p className="info_desc">4.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
                <p className="info_title">5. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                <p className="info_desc">5.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации.</p>
                <p className="info_desc">5.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет ответственности, если данная конфиденциальная информация:</p>
                <p className="info_desc">5.2.1. Стала публичным достоянием до ее утраты или разглашения.</p>
                <p className="info_desc">5.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.</p>
                <p className="info_desc">5.2.3. Была разглашена с согласия Пользователя.</p>
                <p className="info_title">6. РАЗРЕШЕНИЕ СПОРОВ</p>
                <p className="info_desc">6.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией Сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                <p className="info_desc">6.2. Получатель претензии в течение 30 календарных дней со дня получения претензии письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                <p className="info_desc">6.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим законодательством Российской Федерации.</p>
                <p className="info_desc">6.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется действующее законодательство Российской Федерации.</p>
                <p className="info_title">7. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</p>
                <p className="info_desc">7.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
                <p className="info_desc">7.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
            </div>
        </div>
    );
}
